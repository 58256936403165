import { Loadable } from "services";
import withCookieCheck from "services/withCookie"; // Make sure to use the correct path here

const routes: IRouter[] = [
  {
    path: "/address_check",
    component: withCookieCheck(Loadable(() => import("pages/000"))),
    exact: true
  },
  {
    path: "/login",
    component: withCookieCheck(Loadable(() => import("pages/001"))),
    exact: true
  },
  {
    path: "/001",
    component: withCookieCheck(Loadable(() => import("pages/001"))),
    exact: true
  },
  {
    path: "/002",
    component: withCookieCheck(Loadable(() => import("pages/002"))),
    exact: true
  },
  {
    path: "/003",
    component: withCookieCheck(Loadable(() => import("pages/003"))),
    exact: true
  },
  {
    path: "/012",
    component: withCookieCheck(Loadable(() => import("pages/003"))),
    exact: true
  },
  {
    path: "/013",
    component: withCookieCheck(Loadable(() => import("pages/003"))),
    exact: true
  },
  {
    path: "/003",
    component: withCookieCheck(Loadable(() => import("pages/003"))),
    exact: true
  },
  {
    path: "/101",
    component: withCookieCheck(Loadable(() => import("pages/error/101"))),
    exact: true
  },
  {
    path: "/102",
    component: withCookieCheck(Loadable(() => import("pages/error/102"))),
    exact: true
  },
  {
    path: "/103",
    component: withCookieCheck(Loadable(() => import("pages/error/103"))),
    exact: true
  },
  {
    path: "/104",
    component: withCookieCheck(Loadable(() => import("pages/error/104"))),
    exact: true
  },
  {
    path: "/105",
    component: withCookieCheck(Loadable(() => import("pages/error/105"))),
    exact: true
  },
  {
    path: "/106",
    component: withCookieCheck(Loadable(() => import("pages/error/106"))),
    exact: true
  },
  {
    path: "*",
    component: withCookieCheck(Loadable(() => import("pages/error/404"))),
    exact: true
  }
];

export default routes;
