import React, { createContext, useContext, useState, ReactNode } from "react";
import { Locale } from "antd/es/locale-provider";

export type ThemeType = "light" | "dark";

export interface AppCtxState {
  loggedUser?: any;
  theme?: ThemeType;
  locale?: Locale;
}

export interface AppCtxProps {
  appState: AppCtxState | null;
  changeAppState: (state: AppCtxState) => void;
}

const defaultValue: AppCtxProps = {
  appState: null,
  changeAppState: () => {}
};

export const AppCtx = createContext<AppCtxProps>(defaultValue);
export const useAppCtx = (): AppCtxProps => useContext(AppCtx);

interface AppCtxProviderProps {
  children: ReactNode;
}

export const AppCtxProvider: React.FC<AppCtxProviderProps> = ({ children }) => {
  const [appState, setState] = useState<AppCtxState | null>(
    defaultValue.appState
  );
  const changeAppState = (newState: AppCtxState) => setState(() => newState);

  return (
    <AppCtx.Provider value={{ appState, changeAppState }}>
      {children}
    </AppCtx.Provider>
  );
};
