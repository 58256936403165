import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the user state type
interface UserState {
    accountNumber: string | null;
    auID_ID: string | null;
    auID_PW: string | null;
    phoneNumber: string | null;
    emailAddress: string | null;
    serviceType: string | null;
}

// Initialize the user state
const initialState: UserState = {
    accountNumber: null,
    auID_ID: null,
    auID_PW: null,
    phoneNumber: null,
    emailAddress: null,
    serviceType: null,
};

// Create the user slice
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {
            state.accountNumber = action.payload.accountNumber;
            state.auID_ID = action.payload.auID_ID;
            state.auID_PW = action.payload.auID_PW;
            state.phoneNumber = action.payload.phoneNumber;
            state.emailAddress = action.payload.emailAddress;
            state.serviceType = action.payload.serviceType;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
