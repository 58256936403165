import React from "react";
import { Spin } from "antd";
import styles from "./styles.module.less";

const Component: React.FC = () => (
  <div className={styles.container}>
    <Spin className={styles.loader} />
  </div>
);

export default Component;
