import { FC, useEffect, useState } from "react";
import styles from "./styles.module.less";

import { useLogInvoice } from "services/logger";

type HeaderType = {
  isMobile: boolean;
};

export const HeaderButtons: FC<HeaderType> = () => {
  const [isMobile, setIsMobile] = useState(false);

  const { hostname } = window.location;

  const dataOazukariFaqUrl = process.env.REACT_APP_DATA_OAZUKARI_FAQ_URL || "";
  const aumailFaqUrl = process.env.REACT_APP_AUMAIL_FAQ_URL || "";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickInq = () => {
    if (hostname.includes("data-oazukari-invoice")) {
      window.open(dataOazukariFaqUrl, "_blank", "noopener,noreferrer");
    } else {
      window.open(aumailFaqUrl, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className={styles.headerButtonsContainer}>
      <a
        onClick={handleClickInq}
        className={styles.linkStyle}
        target="_blank"
        rel="noopener noreferrer"
      >
        FAQ
      </a>
    </div>
  );
};
