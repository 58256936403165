import React, { useEffect, useMemo } from "react";
import Layout from "layouts";
import {
  Redirect,
  Router,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import routes from "routes";

import Healthcheck from "./pages/healthcheck";
import { useCookies } from "react-cookie";
import ActivityMonitor from "services/ActivityMonitor";
import { useLogInvoice } from "services/logger";

const App: React.FC = () => {
  const history = useHistory();
  const [cookies] = useCookies(["CCAAUTKT"]);

  const hostname = useMemo(() => window.location.hostname, []);
  const CCAAUTKT = cookies.CCAAUTKT;
  const logInvoice = useLogInvoice();

  const location = useLocation();

  const REDIRECT_BASE_URL = process.env.REACT_APP_REDIRECT_BASE_URL || "";
  const DATA_OAZUKARI_URL = process.env.REACT_APP_DATA_OAZUKARI_URL || "";
  const AUMAIL_URL = process.env.REACT_APP_AUMAIL_URL || "";

  useEffect(() => {
    const fetchData = async () => {
      await logInvoice(location?.pathname);
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    if (!CCAAUTKT) {
      if (hostname.includes("data-oazukari-invoice")) {
        window.location.href = `${REDIRECT_BASE_URL}${DATA_OAZUKARI_URL}`;
      } else {
        window.location.href = `${REDIRECT_BASE_URL}${AUMAIL_URL}`;
      }
    }
  }, [CCAAUTKT, hostname]);

  let title = "";
  if (window.location.hostname.includes("data-oazukari-invoice")) {
    title = "データお預かり容量追加";
  }
  if (window.location.hostname.includes("aumail")) {
    title = "auメール持ち運び";
  }
  document.title = title;

  return (
    <>
      <Router history={history}>
        <ActivityMonitor />
        <Switch>
          <Route
            exact
            path="/invoice-helthcheck-page"
            component={Healthcheck}
          />
          <Route>
            <Layout>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/001" />} />

                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    exact={!!route.exact}
                  />
                ))}
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;

