// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__4eChT {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n.styles_loader__IfP5P .styles_ant-spin-dot__OZhGY {\n  vertical-align: middle;\n}\n", "",{"version":3,"sources":["webpack://./src/components/loading/styles.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACF;AAEA;EAEI,sBAAA;AADJ","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n}\n\n.loader {\n  .ant-spin-dot {\n    vertical-align: middle;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__4eChT",
	"loader": "styles_loader__IfP5P",
	"ant-spin-dot": "styles_ant-spin-dot__OZhGY"
};
export default ___CSS_LOADER_EXPORT___;
