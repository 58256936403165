import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useLogInvoice } from "./logger";

const ActivityMonitor = () => {
  const idleTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const history = useHistory();

  const resetIdleTimer = () => {
    if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
    idleTimerRef.current = setTimeout(() => {
      history.push("/101");
    }, 15 * 60 * 1000);
  };

  useEffect(() => {
    window.addEventListener("click", resetIdleTimer);
    resetIdleTimer();

    return () => {
      if (idleTimerRef.current) clearTimeout(idleTimerRef.current);
      window.removeEventListener("click", resetIdleTimer);
    };
  }, []);

  return null;
};

export default ActivityMonitor;
