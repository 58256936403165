import React from "react";

const Component: React.FC = () => {
  return (
    <>
      <div style={{ textAlign: "center", marginTop: 80, marginLeft: 40 }}>
        <p style={{ fontSize: 20 }} className="text-left">
          「Click here to use the service{" "}
          <a
            href="https://aumailportability-invoice.au.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            [https://aumail portability-invoice.au.com]
          </a>
          」
        </p>
      </div>
    </>
  );
};

export default Component;
