import React, { useEffect, ReactNode, useState, useMemo } from "react";
import { Layout } from "antd";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import styles from "./styles.module.less";
import useQuery from "hooks/useQuery";
import { useCookies } from "react-cookie";
import aulogo from "./au.svg";
import { Button, Row, Col } from "antd";
import { setUser } from "redux/userSlice";
import { AppDispatch } from "redux/store";
import { useDispatch } from "react-redux";
import { HeaderButtons } from "pages/001/sub-components/header-buttons";
import { useLogInvoice } from "services/logger";

interface ComponentProps extends RouteComponentProps {
  children: ReactNode;
}

const AppLayout: React.FC<ComponentProps> = ({ children }) => {
  const query = useQuery();
  const [, setCookie, removeCookie] = useCookies(["auId", "operatorId"]);

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const hostname = useMemo(() => window.location.hostname, []);
  const logInvoice = useLogInvoice();
  const [titleHeader, setTitleHeader] = useState("auメール持ち運び");

  const auSiteUrl = process.env.REACT_APP_AU_SITE_URL || "";
  const logoutBaseUrl = process.env.REACT_APP_LOGOUT_BASE_URL || "";
  const dataOazukariUrl = process.env.REACT_APP_DATA_OAZUKARI_URL || "";
  const aumailUrl = process.env.REACT_APP_AUMAIL_URL || "";

  useEffect(() => {
    if (hostname.includes("data-oazukari-invoice")) {
      setTitleHeader("データお預かり");
    } else {
      setTitleHeader("auメール持ち運び");
    }
  }, []);

  useEffect(() => {
    removeCookie("auId");
    removeCookie("operatorId");

    const operatorId = query.get("operatorid");
    const auId = query.get("auoneid");

    if (operatorId) {
      setCookie("operatorId", operatorId);
    }
    if (auId) {
      setCookie("auId", auId);
    }
  }, [query, removeCookie, setCookie]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickAuSite = async () => {
    window.open(auSiteUrl, "_blank", "noopener,noreferrer");
  };

  const handleLogout = async () => {
    await logInvoice("016");

    await dispatch(
      setUser({
        accountNumber: null,
        auID_ID: null,
        auID_PW: null,
        phoneNumber: null,
        emailAddress: null,
        serviceType: null
      })
    );
    if (hostname.includes("data-oazukari-invoice")) {
      window.location.href = `${logoutBaseUrl}?targeturl=${dataOazukariUrl}`;
    } else {
      window.location.href = `${logoutBaseUrl}?targeturl=${aumailUrl}`;
    }
  };

  return (
    <>
      <header className={styles.subHeader}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{
                height: !isMobile ? 25 : 15,
                paddingLeft: 20,
                fill: "#EA5300"
              }}
              className="img-fluid"
              src={aulogo}
              alt="Login Cover"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px"
            }}
          >
            <Button
              style={{ borderRadius: 10 }}
              target="_blank"
              onClick={handleClickAuSite}
              className={styles.smlButton}
            >
              au ID会員情報
            </Button>
            <Button
              style={{ borderRadius: 10 }}
              onClick={handleLogout}
              className={styles.smlButton}
            >
              ログアウト
            </Button>
          </div>
        </div>
      </header>
      <Row
        className={styles.rowStyle}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      >
        <Col xs={18} sm={18} md={18}>
          <h1 className={styles.topTitle}>
            {hostname.includes("data-oazukari-invoice")
              ? "データお預かり容量追加"
              : "auメール持ち運び"}
            <br />
            請求書発行
          </h1>
        </Col>
        <Col xs={6} sm={6} md={6}>
          <HeaderButtons isMobile={isMobile} />
        </Col>
      </Row>
      <Layout.Content className={styles.container}>
        <div className={styles.wrapper}>{children}</div>
      </Layout.Content>
    </>
  );
};

export default withRouter(AppLayout);
