// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_headerButtonsContainer__0cfPk {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n.styles_headerButtonsContainerMobile__v0Gx2 {\n  justify-content: center;\n}\n.styles_linkStyle__LpDB- {\n  text-decoration: underline;\n  color: blue;\n  font-size: 22px;\n}\n@media (max-width: 768px) {\n  .styles_headerButtonsContainer__0cfPk {\n    justify-content: end;\n  }\n  .styles_linkStyle__LpDB- {\n    font-size: 15px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/001/sub-components/styles.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;AACF;AAEA;EACE,uBAAA;AAAF;AAGA;EACE,0BAAA;EACA,WAAA;EACA,eAAA;AADF;AAIA;EACE;IACE,oBAAA;EAFF;EAKA;IACE,eAAA;EAHF;AACF","sourcesContent":[".headerButtonsContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.headerButtonsContainerMobile {\n  justify-content: center;\n}\n\n.linkStyle {\n  text-decoration: underline;\n  color: blue;\n  font-size: 22px;\n}\n\n@media (max-width: 768px) {\n  .headerButtonsContainer {\n    justify-content: end;\n  }\n\n  .linkStyle {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerButtonsContainer": "styles_headerButtonsContainer__0cfPk",
	"headerButtonsContainerMobile": "styles_headerButtonsContainerMobile__v0Gx2",
	"linkStyle": "styles_linkStyle__LpDB-"
};
export default ___CSS_LOADER_EXPORT___;
