import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import store from "./redux/store";
import { AppCtxProvider } from "contexts";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import "./styles/index.less";
import { ConfigProvider } from "antd";
import jaJP from "antd/lib/locale/ja_JP";

let title = "";
if (window.location.hostname.includes("data-oazukari-invoice")) {
  title = "データお預かり容量追加";
}
if (window.location.hostname.includes("aumail")) {
  title = "auメール持ち運び";
}

document.title = title;
ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <AppCtxProvider>
        <ConfigProvider locale={jaJP}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConfigProvider>
      </AppCtxProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById("app")
);

reportWebVitals();
