import { http } from "services";
import { RootState } from "redux/store";
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useLogInvoice = () => {
    const hostname = useMemo(() => window.location.hostname, []);
    const systemUser = useSelector((state: RootState) => state.user);

    const logInvoice = async (logId: any) => {
        try {

            const loggerResponse = await http.get<any>("/invoices/logger", {
                params: {
                    systemAuId: systemUser?.auID_ID,
                    serviceId: hostname.includes("data-oazukari-invoice") ? "2" : "1",
                    message: logId,
                },
                timeout: 60000
            });


        } catch (error) {
            console.error(error);
        }
    };

    return logInvoice;
};
