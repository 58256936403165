import { ComponentType } from "react";

const withCookieCheck = <P extends object>(
  WrappedComponent: ComponentType<P>
): ComponentType<P> => {
  return (props: P) => {
    return <WrappedComponent {...(props as P)} />;
  };
};

export default withCookieCheck;
